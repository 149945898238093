import { getFunctions, httpsCallable } from "firebase/functions";

export function useFunc<TRequest = any, TResponse = any>(functionName: string) {
  const functions = getFunctions();

  // functions.customDomain = "http://localhost:5001";
  const ecpayTradeTokenCallable = httpsCallable<TRequest, TResponse>(
    functions,
    functionName
  );
  return ecpayTradeTokenCallable;
}
